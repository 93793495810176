<template>
  <div>
    <div class="router-view-container">
      <ValidationObserver v-if="is_API_loaded" v-slot="{ invalid }">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>

          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </div>
        <main class="space-y-10">
          <div class="space-y-4">
            <section class="block">
              <div class="leftContent">
                <h3 class="h3">結帳設定</h3>
                <p>管理顧客的結帳權限。</p>
              </div>

              <div class="box">
                <div class="switch">
                  <vs-switch color="success" v-model="data.iscart" />
                  <div class="label">
                    <p class="title">購物車功能</p>
                    <p class="description" v-if="data.iscart">顧客可以將商品加入購物車和結帳。</p>
                    <p class="description" v-else>顧客無法加入購物車和結帳。</p>
                  </div>
                </div>
                <div class="switch">
                  <vs-switch color="success" v-model="data.istourists" />
                  <div class="label">
                    <p class="title">非會員結帳</p>
                    <p class="description" v-if="data.istourists">無論顧客是否為會員，皆可以進行結帳。</p>
                    <p class="description" v-else>只有會員並且登入後，才可以進行結帳。</p>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <hr />

          <div class="flex justify-end">
            <vs-button @click="savePage(invalid)" size="large" :disabled="invalid" ref="savePage"
              ><span class="font-medium">儲存</span></vs-button
            >
          </div>
        </main>
      </ValidationObserver>

      <!-- 骨架屏 -->
      <div class="space-y-4" v-else>
        <section v-for="(item, index) in 2" :key="index" class="block">
          <div class="leftContent">
            <skeleton class="mt-4" width="100px" />
            <skeleton width="200px" height="10px" />
            <skeleton width="200px" height="10px" />
            <skeleton width="200px" height="10px" />
          </div>
          <div class="box">
            <div class="flex items-center justify-between">
              <skeleton width="100px" />
              <skeleton width="100px" />
            </div>
            <div class="flex items-start flex-col space-y-2">
              <skeleton v-for="(item, index) in 3" :key="index" width="200px" />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'store_checkout',
  data() {
    return {
      data: {},
      is_API_loaded: false,
    }
  },
  computed: {},
  created() {
    this.loadData()
  },
  methods: {
    // 4.3.4.1 結帳設定資料-讀取
    loadData() {
      this.$axios({
        url: 'front/set/store/getCheckoutData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          let data = res.data.Data

          // 將 1 換成 true
          function numberToBoolean(val) {
            if (val) return true
            else return false
          }

          data.iscart = numberToBoolean(data.iscart)
          data.istourists = numberToBoolean(data.istourists)

          this.data = data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 4.2.1.2 商店資料-修改
    savePage(invalid) {
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.savePage,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/set/store/uCheckoutData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          iscart: this.data.iscart ? 1 : 0,
          istourists: this.data.istourists ? 1 : 0,
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.loadData()
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.block {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0;
}

.leftContent {
  @apply w-full sm:w-1/3 space-y-4 flex-shrink-0 sm:mt-4;
}

.box {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-4;
}

.title {
  @apply text-sm text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

.switch {
  @apply flex items-start space-x-2;

  .label {
    @apply mt-1 space-y-1;

    .title {
      @apply text-gray-text_dark;
    }

    .description {
      @apply text-gray-400;
    }
  }
}
</style>
